import Marquee from "react-fast-marquee";
import Region from "../../Components/Region";
import "./regions.scss";

import ellipse from "../../img/ellipse.svg";
function Regions() {
  return (
    <section id="regions" className="regions">
      <div className="regions-content">
        <div className="regions-content-title">
          <h2>Регионы присутствия</h2>
        </div>
        <div className="regions-content-string">
          <img className="ellipse" width={624} src={ellipse} alt="ellipse" />
          <Marquee
            speed={50}
            direction="right"
            pauseOnHover={true}
            gradient={false}
          >
            <Region text="Москва" />
            <Region text="Московская область" />
            <Region text="Санкт-Петербург" />
            <Region text="Краснодар" />
            <Region text="Самара" />
            <Region text="Новосибирск" />
            <Region text="Екатеринбург" />
          </Marquee>
        </div>
      </div>
    </section>
  );
}

export default Regions;
