import { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { LocationContext } from "../../context/LocationState";
import { LocationState } from "../../context";
import InputMask from "react-input-mask";
import PhoneInput from "react-phone-input-2";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/esm/locale/ru/index.js";
import { AddressSuggestions } from "react-dadata";
import { Spinner, Toast } from "react-bootstrap";

import "react-phone-input-2/lib/style.css";
import "./order.scss";
import "react-datepicker/dist/react-datepicker.css";

function Order() {
  registerLocale("ru", ru);
  const [timeDelivery, setTimeDelivery] = useState("Как можно скорее");
  const [howDelivery, setHowDelivery] = useState("Пешком");
  const [totalWeight, setTotalWeight] = useState("до 1 кг");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [calc, setCalc] = useState("0");
  const [isCalculate, setIsCalculate] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [adresFrom, setAdressFrom] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [paymentPoint, setPaymentPoint] = useState("1");
  const [paymentFirst, setPaymentFirst] = useState("");
  const [paymentSecond, setPaymentSecond] = useState("");

  const { region } = useContext(LocationContext);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  function chengeTimeDelivery(e) {
    setTimeDelivery(e.target.value);
  }
  function chengeHowDelivery(e) {
    setHowDelivery(e.target.value);
  }

  function changeWeight(e) {
    setTotalWeight(e.target.value);
  }

  function changePaymentMethod(e) {
    setPaymentMethod(e.target.value);
  }
  function changePaymentPoint(e) {
    setPaymentPoint(e.target.value);
  }

  const onCalc = async () => {
    setIsCalculate(true);
    const obj = getValues();
    const fullObj = {
      ...obj,
      howDelivery,
      timeDelivery,
      totalWeight,
      startDate,
      endDate,
    };
    await fetch("./calc.php", {
      body: JSON.stringify(fullObj),
      method: "POST",
    })
      .then((res) => {
        if (res.ok) {
          setIsCalculate(false);
        }
        return res.json();
      })
      .then((data) => {
        if (data.is_successful) {
          setCalc(data.order.payment_amount);
        }
      });
  };

  const onSubmit = async (value) => {
    const order = {
      ...value,
      howDelivery,
      timeDelivery,
      totalWeight,
      endDate,
      startDate,
      paymentMethod,
      paymentPoint,
      currency: +calc + (+calc / 100) * 20 + 50,
    };
    setIsLoading(true);

    const response = await fetch("./order.php", {
      body: JSON.stringify(order),
      method: "POST",
    });

    if (response.ok) {
      const res = await response.json();
      console.log(res);
      if (res.is_successful) {
        await fetch("./phpmailer/order.php", {
          body: JSON.stringify(order),
          method: "POST",
        });
        setIsLoading(false);
        reset();
        setShow(true);
        setPaymentFirst("");
        setPaymentSecond("");
        setInterval(
          (window.location.href = "https://lk.courier-go.su/orders"),
          2000
        );
      } else {
        setError(true);
        setIsLoading(false);
      }
    } else {
      setError(true);
    }
    console.log(order);
  };

  const obj = getValues();
  const adress_from = obj.adress_from;
  const adress_where = obj.adress_where;

  useEffect(() => {
    onCalc();
  }, [
    howDelivery,
    timeDelivery,
    adress_from,
    adress_where,
    totalWeight,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="container">
        <form className="order_form" onSubmit={handleSubmit(onSubmit)}>
          <Toast
            style={{
              position: "fixed",
              top: "60px",
              right: "20px",
              zIndex: 9999,
            }}
            show={show}
            onClick={() => setShow(false)}
          >
            <Toast.Header>
              <strong className="me-auto">Заказ успешно отправлен</strong>
            </Toast.Header>
            <Toast.Body>
              Как только назначим вам курьера, он обязательно с Вами свяжется!
            </Toast.Body>
          </Toast>
          <Toast
            style={{
              position: "fixed",
              top: "20px",
              right: "20px",
              zIndex: 9999,
            }}
            show={error}
            onClick={() => setError(false)}
          >
            <Toast.Header>
              <strong className="me-auto">Что-то пошло не так...</strong>
            </Toast.Header>
            <Toast.Body>
              Проверьте введенные данные или повторите попытку позднее.
            </Toast.Body>
          </Toast>
          <h1>Форма заказа</h1>
          <div className="order_form-owner">
            <div className="order_form-owner-title">
              <h2>Контактные данные заказчика:</h2>
            </div>
            <div className="order_form-owner-data">
              <div className="group">
                <div className="group_item">
                  <label>Фамилия:</label>
                  <input
                    type="text"
                    {...register("lastname", {
                      required: true,
                      validate: (value) => value !== "",
                    })}
                    placeholder="Иванов"
                  />

                  {errors.lastname && <p>Введите Вашу фамилию</p>}
                </div>
                <div className="group_item">
                  <label>Имя:</label>
                  <input
                    type="text"
                    {...register("firstname", {
                      required: true,
                      validate: (value) => value !== "",
                    })}
                    placeholder="Петр"
                  />
                  {errors.firstname && <p>Введите Ваше имя</p>}
                </div>
              </div>
              <div className="group">
                <div className="group_item">
                  <label>Отчество:</label>
                  <input
                    type="text"
                    {...register("middlename", {
                      required: true,
                      validate: (value) => value !== "",
                    })}
                    placeholder="Алексеевич"
                  />
                  {errors.middlename && <p>Введите Ваше отчество</p>}
                </div>
                <div className="group_item">
                  <label>Номер телефона:</label>
                  <Controller
                    control={control}
                    name="phone"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <PhoneInput
                          inputClass="shadow-none"
                          placeholder="+7 (999) 999-99-99"
                          onChange={onChange}
                          value={value}
                          country={"ru"}
                          disableDropdown={true}
                        />
                      </>
                    )}
                  />
                  {errors["phone"] && <p>Введите ваш номер телефона</p>}
                </div>
              </div>
              <div className="group">
                <div className="group_item">
                  <label>Почта:</label>
                  <input
                    {...register("email", {
                      required: true,
                      validate: (value) => value !== "",
                    })}
                    type="email"
                    placeholder="ivaninf@yandex.com"
                    style={{
                      width: "100%",
                    }}
                  />

                  {errors.email && <p>Введите Вашу почту</p>}
                </div>
              </div>
            </div>
          </div>
          <div className="order_form-info">
            <div className="order_form-info-title">
              <h2>Информация о доставке:</h2>
            </div>
            <div className="order_form-info-data">
              <div className="group">
                <div className="group_item">
                  <label>Когда доставить:</label>
                  <div className="checkbox_form">
                    <label className="check option">
                      <input
                        className="check__input"
                        type="checkbox"
                        name="checkbox"
                        value="Как можно скорее"
                        checked={
                          timeDelivery === "Как можно скорее" ? true : false
                        }
                        onChange={(e) => {
                          chengeTimeDelivery(e);
                        }}
                      />
                      <div className="check__box">Как можно скорее</div>
                    </label>
                    <label className="check option">
                      <input
                        className="check__input"
                        type="checkbox"
                        name="checkbox"
                        value="Запланировать"
                        checked={
                          timeDelivery === "Запланировать" ? true : false
                        }
                        onChange={(e) => {
                          chengeTimeDelivery(e);
                          // onCalc();
                        }}
                      />
                      <div className="check__box">Запланировать</div>
                    </label>
                    <label className="check option">
                      <input
                        className="check__input"
                        type="checkbox"
                        disabled
                        name="checkbox"
                        value="Интервал"
                        checked={timeDelivery === "Интервал" ? true : false}
                        onChange={(e) => {
                          chengeTimeDelivery(e);
                          // onCalc();
                        }}
                      />
                      <div className="check__box">Интервал</div>
                    </label>

                    {/* {timeDelivery === "Запланировать" && (
          <div>
            <label>Укажите дату:</label>
            <input
              {...register("plane", {
                required: true,
                validate: (value) => value !== "",
              })}
              type="text"
              placeholder="01.01.2023"
              style={{
                width: "100%",
              }}
            />
            {errors.plane && <p>Укажите дату</p>}
          </div>
        )} */}
                    {timeDelivery === "Интервал" && (
                      <div>
                        <label>Укажите интервал:</label>
                        <input
                          {...register("interval", {
                            required: true,
                            validate: (value) => value !== "",
                          })}
                          type="text"
                          placeholder="01.01.2023 - 03.01.20223"
                          style={{
                            width: "100%",
                          }}
                        />
                        {errors.interval && <p>Укажите интервал даты</p>}
                      </div>
                    )}
                  </div>
                  {timeDelivery !== "Интервал" && (
                    <>
                      <label>Как доставить:</label>
                      <div className="checkbox_form">
                        <label className="check option">
                          <input
                            className="check__input"
                            type="checkbox"
                            name="checkbox"
                            value="Пешком"
                            checked={howDelivery === "Пешком" ? true : false}
                            onChange={(e) => {
                              chengeHowDelivery(e);
                              // onCalc();
                            }}
                          />
                          <div className="check__box">Пешком</div>
                        </label>
                        <label className="check option">
                          <input
                            className="check__input"
                            type="checkbox"
                            name="checkbox"
                            value="Легковой автомобиль"
                            checked={
                              howDelivery === "Легковой автомобиль"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              chengeHowDelivery(e);
                              // onCalc();
                            }}
                          />
                          <div className="check__box">Легковой автомобиль</div>
                        </label>
                        <label className="check option">
                          <input
                            className="check__input"
                            type="checkbox"
                            name="checkbox"
                            value="Грузовой автомобиль"
                            checked={
                              howDelivery === "Грузовой автомобиль"
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              chengeHowDelivery(e);
                              // onCalc();
                            }}
                          />
                          <div className="check__box">Грузовой автомобиль</div>
                        </label>
                      </div>
                    </>
                  )}

                  {howDelivery === "Пешком" && timeDelivery !== "Интервал" && (
                    <>
                      <label>Вес посылки:</label>
                      <div className="checkbox_form weight">
                        <label className="check option">
                          <input
                            className="check__input"
                            type="checkbox"
                            name="checkbox"
                            value="до 1 кг"
                            checked={totalWeight === "до 1 кг" ? true : false}
                            onChange={(e) => {
                              changeWeight(e);
                            }}
                          />
                          <div className="check__box">до 1 кг</div>
                        </label>
                        <label className="check option">
                          <input
                            className="check__input"
                            type="checkbox"
                            name="checkbox"
                            value="до 5 кг"
                            checked={totalWeight === "до 5 кг" ? true : false}
                            onChange={(e) => {
                              changeWeight(e);
                            }}
                          />
                          <div className="check__box">до 5 кг</div>
                        </label>
                        <label className="check option">
                          <input
                            className="check__input"
                            type="checkbox"
                            name="checkbox"
                            value="до 10 кг"
                            checked={totalWeight === "до 10 кг" ? true : false}
                            onChange={(e) => {
                              changeWeight(e);
                            }}
                          />
                          <div className="check__box">до 10 кг</div>
                        </label>
                        <label className="check option">
                          <input
                            className="check__input"
                            type="checkbox"
                            name="checkbox"
                            value="до 15 кг"
                            checked={totalWeight === "до 15 кг" ? true : false}
                            onChange={(e) => {
                              changeWeight(e);
                            }}
                          />
                          <div className="check__box">до 15 кг</div>
                        </label>
                      </div>
                    </>
                  )}
                  {howDelivery === "Легковой автомобиль" &&
                    timeDelivery !== "Интервал" && (
                      <>
                        <label>Вес посылки:</label>
                        <div className="checkbox_form weight">
                          <label className="check option">
                            <input
                              className="check__input"
                              type="checkbox"
                              name="checkbox"
                              value="до 50 кг"
                              checked={
                                totalWeight === "до 50 кг" ? true : false
                              }
                              onChange={(e) => {
                                changeWeight(e);
                              }}
                            />
                            <div className="check__box">до 50 кг</div>
                          </label>
                          <label className="check option">
                            <input
                              className="check__input"
                              type="checkbox"
                              name="checkbox"
                              value="до 100 кг"
                              checked={
                                totalWeight === "до 100 кг" ? true : false
                              }
                              onChange={(e) => {
                                changeWeight(e);
                              }}
                            />
                            <div className="check__box">до 100 кг</div>
                          </label>
                          <label className="check option">
                            <input
                              className="check__input"
                              type="checkbox"
                              name="checkbox"
                              value="до 150 кг"
                              checked={
                                totalWeight === "до 150 кг" ? true : false
                              }
                              onChange={(e) => {
                                changeWeight(e);
                              }}
                            />
                            <div className="check__box">до 150 кг</div>
                          </label>
                          <label className="check option">
                            <input
                              className="check__input"
                              type="checkbox"
                              name="checkbox"
                              value="до 200 кг"
                              checked={
                                totalWeight === "до 200 кг" ? true : false
                              }
                              onChange={(e) => {
                                changeWeight(e);
                              }}
                            />
                            <div className="check__box">до 200 кг</div>
                          </label>
                        </div>
                      </>
                    )}
                  {howDelivery === "Грузовой автомобиль" &&
                    timeDelivery !== "Интервал" && (
                      <>
                        <label>Вес посылки:</label>
                        <div className="checkbox_form weight">
                          <label className="check option">
                            <input
                              className="check__input"
                              type="checkbox"
                              name="checkbox"
                              value="до 500 кг"
                              checked={
                                totalWeight === "до 500 кг" ? true : false
                              }
                              onChange={(e) => {
                                changeWeight(e);
                              }}
                            />
                            <div className="check__box">до 500 кг</div>
                          </label>
                          <label className="check option">
                            <input
                              className="check__input"
                              type="checkbox"
                              name="checkbox"
                              value="до 700 кг"
                              checked={
                                totalWeight === "до 700 кг" ? true : false
                              }
                              onChange={(e) => {
                                changeWeight(e);
                              }}
                            />
                            <div className="check__box">до 700 кг</div>
                          </label>
                          <label className="check option">
                            <input
                              className="check__input"
                              type="checkbox"
                              name="checkbox"
                              value="до 1000 кг"
                              checked={
                                totalWeight === "до 1000 кг" ? true : false
                              }
                              onChange={(e) => {
                                changeWeight(e);
                              }}
                            />
                            <div className="check__box">до 1000 кг</div>
                          </label>
                          <label className="check option">
                            <input
                              className="check__input"
                              type="checkbox"
                              name="checkbox"
                              value="до 1500 кг"
                              checked={
                                totalWeight === "до 1500 кг" ? true : false
                              }
                              onChange={(e) => {
                                changeWeight(e);
                              }}
                            />
                            <div className="check__box">до 1500 кг</div>
                          </label>
                        </div>
                      </>
                    )}
                </div>
              </div>
              <div className="group">
                <div className="group_item"></div>
              </div>
            </div>
          </div>
          <div className="order_form-from">
            <div className="order_form-from-title">
              <h2>Отправление:</h2>
            </div>
            <div className="order_form-from-data">
              <div className="group">
                <div className="group_item">
                  <label>От кого:</label>
                  <input
                    type="text"
                    {...register("name_from", {
                      required: true,
                      validate: (value) => value !== "",
                    })}
                    placeholder="Иван"
                  />
                  {errors.name_from && <p>Введите Имя</p>}
                </div>
                <div className="group_item">
                  <label>Номер телефона:</label>
                  <Controller
                    control={control}
                    name="phone_from"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <PhoneInput
                          inputClass="shadow-none"
                          placeholder="+7 (999) 999-99-99"
                          onChange={onChange}
                          value={value}
                          country={"ru"}
                          disableDropdown={true}
                        />
                      </>
                    )}
                  />
                  {errors["phone_from"] && (
                    <p>Введите номер телефона отправителя</p>
                  )}
                </div>
              </div>
              <div className="group">
                <div className="group_item">
                  {timeDelivery === "Запланировать" && (
                    <>
                      <label>Начать:</label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        placeholderText="Выберите дату и время"
                        locale={ru}
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm"
                      />
                    </>
                  )}
                  <label>Адрес:</label>
                  <Controller
                    control={control}
                    name="adress_from"
                    render={({ field }) => (
                      <>
                        <AddressSuggestions
                          containerClassName="form_adress"
                          {...register("adress_from", {
                            required: true,
                            validate: (value) => value !== "",
                            onChange: (e) => {
                              onCalc();
                              setAdressFrom(e.target.value.value);
                              setPaymentFirst(e.target.value.value);
                            },
                          })}
                          {...field}
                          count={5}
                          filterLocations={[
                            {
                              city: `${region}`,
                            },
                          ]}
                          inputProps={{
                            placeholder: "г. Москва ул. Новый Арбат 12",
                          }}
                          token="eb9dbc9d8b76b68399bd5aa3a3e5c7c31557e11d"
                        />
                      </>
                    )}
                  />

                  {errors.adress_from && (
                    <p>Укажите откуда забрать посылку в пределах города</p>
                  )}
                </div>
              </div>
              <div className="group">
                <div className="group_item">
                  <label>Комментарий:</label>
                  <textarea
                    type="text"
                    {...register("note_from")}
                    placeholder="Укажите комментарий для курьера"
                    rows={4}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="order_from-where">
            <div className="order_from-where-title">
              <h2>Получение:</h2>
            </div>
            <div className="order_from-where-data">
              <div className="group">
                <div className="group_item">
                  <label>Кому:</label>
                  <input
                    type="text"
                    {...register("name_where", {
                      required: true,
                      validate: (value) => value !== "",
                    })}
                    placeholder="Петр"
                  />
                  {errors.name_from && <p>Введите Имя получателя</p>}
                </div>
                <div className="group_item">
                  <label>Номер телефона:</label>
                  <Controller
                    control={control}
                    name="phone_where"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <PhoneInput
                          inputClass="shadow-none"
                          placeholder="+7 (999) 999-99-99"
                          onChange={onChange}
                          value={value}
                          country={"ru"}
                          disableDropdown={true}
                        />
                      </>
                    )}
                  />
                  {errors["phone_where"] && (
                    <p>Введите номер телефона получателя</p>
                  )}
                </div>
              </div>
              <div className="group">
                <div className="group_item">
                  {timeDelivery === "Запланировать" && (
                    <>
                      <label>Закончить:</label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        showTimeSelect
                        placeholderText="Выберите дату и время"
                        locale={ru}
                        timeFormat="HH:mm"
                        dateFormat="MMMM d, yyyy h:mm"
                      />
                    </>
                  )}
                  <label>Адрес:</label>

                  <Controller
                    control={control}
                    name="adress_where"
                    render={({ field }) => (
                      <>
                        <AddressSuggestions
                          containerClassName="form_adress"
                          {...register("adress_where", {
                            required: true,
                            validate: (value) =>
                              value !== "" && value[2] !== adresFrom[2],
                            onChange: (e) => {
                              onCalc();
                              setPaymentSecond(e.target.value.value);
                            },
                          })}
                          {...field}
                          count={5}
                          filterLocations={[
                            {
                              city: `${region}`,
                            },
                          ]}
                          inputProps={{
                            placeholder: "г. Москва ул. Новый Арбат 12",
                          }}
                          token="eb9dbc9d8b76b68399bd5aa3a3e5c7c31557e11d"
                        ></AddressSuggestions>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="group">
                <div className="group_item">
                  <label>Комментарий:</label>
                  <textarea
                    type="text"
                    {...register("note_where")}
                    placeholder="Укажите комментарий для курьера"
                    rows={4}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="order_form-box">
            <div className="order_form-box-title">
              <h2>Информация о грузе:</h2>
            </div>
            <div className="order_form-box-data">
              <div className="group">
                <div className="group_item">
                  <label>Что везем:</label>
                  <input
                    type="text"
                    {...register("object", {
                      required: true,
                      validate: (value) => value !== "",
                    })}
                    placeholder="Лекарства, продукты, книги..."
                  />
                  {errors.object && <p>Укажите что будем везти</p>}
                </div>
              </div>
              <div className="group">
                <div className="group_item">
                  <label>Примерные габариты груза:</label>

                  <Controller
                    control={control}
                    name="size"
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <InputMask
                          {...register("size", {
                            required: true,
                            validate: (value) => value !== "",
                          })}
                          name="size"
                          mask="99х99х99см"
                          maskChar={null}
                          placeholder="Например 30х30х30см"
                          style={{
                            width: "100%",
                          }}
                        >
                          {(inputProps) => (
                            <input {...inputProps} type="text" />
                          )}
                        </InputMask>
                      </>
                    )}
                  />
                  {errors.size && <p>Укажите габариты груза</p>}
                </div>
              </div>
              <div className="group">
                <div className="group_item">
                  <label>Оценточная стоимость груза:</label>
                  <input
                    type="number"
                    {...register("object_price", {
                      required: true,
                      validate: (value) => value !== "",
                    })}
                    placeholder="до 10000 руб."
                    onBlur={onCalc}
                    max={10000}
                  />
                  {errors.object_price && (
                    <p>Укажите оценочную стоимость груза</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="order_form-payments">
            <div className="order_form-payments-title">
              <h2>Способ оплаты:</h2>
            </div>
            <div className="order_form-payments-data">
              <div className="checkbox_form justify-content-around">
                <label className="check option">
                  <input
                    className="check__input"
                    type="checkbox"
                    name="checkbox"
                    value="cash"
                    checked={paymentMethod === "cash" ? true : false}
                    onChange={(e) => {
                      changePaymentMethod(e);
                    }}
                  />
                  <div className="check__box">Картой курьеру</div>
                </label>
                <label className="check option">
                  <input
                    className="check__input"
                    type="checkbox"
                    name="checkbox"
                    value="card"
                    checked={paymentMethod === "card" ? true : false}
                    onChange={(e) => {
                      changePaymentMethod(e);
                    }}
                  />
                  <div className="check__box">Оплата онлайн</div>
                </label>
              </div>
              <h3 className="text-center">Оплата по адресу:</h3>

              {paymentMethod === "cash" && (
                <div className="cash_points">
                  <label className="check option">
                    <input
                      className="check__input"
                      type="radio"
                      name="radio"
                      value="1"
                      checked={paymentPoint === "1" ? true : false}
                      onChange={(e) => {
                        changePaymentPoint(e);
                      }}
                    />
                    <div className="check__box"> 1. {paymentFirst}</div>
                  </label>

                  <label className="check option">
                    <input
                      className="check__input"
                      type="radio"
                      name="radio"
                      value="2"
                      checked={paymentPoint === "2" ? true : false}
                      onChange={(e) => {
                        changePaymentPoint(e);
                      }}
                    />
                    <div className="check__box"> 2. {paymentSecond}</div>
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="btn-block">
            {isCalculate ? (
              <Spinner animation="border" />
            ) : (
              <h3>Итого: {+calc + (+calc / 100) * 20 + 50} руб.</h3>
            )}
            <button className="btns" type="submit">
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Заказать доставку"
              )}
            </button>
          </div>
          <span style={{ opacity: "0.6", fontSize: "12px" }}>
            Нажимая кнопку "Заказать доставку", вы соглашаетесь с{" "}
            <a href="/docs/privacy.pdf">
              политикой обработки персональных данных
            </a>{" "}
          </span>
        </form>
      </div>
    </>
  );
}

export default Order;
