import { Link } from "react-router-dom";

import "./tarifs.scss";

function Tarifs() {
  return (
    <section id="tarifs" className="tarifs">
      <div className="container">
        <div className="tarifs-content">
          <div className="tarifs-content-title">
            <h2>Тарифы</h2>
          </div>
          <div className="tarifs-content-cards">
            <div className="tarifs-content-cards-item">
              <div className="tarifs-content-cards-item-title">
                <h3>По городу</h3>
              </div>
              <div className="tarifs-content-cards-item-body">
                <ul>
                  <li>Ночная доставка + 90 ₽</li>
                  <li>Перевоз крупногабаритных объектов + 200 ₽</li>
                </ul>
                <span>от 350 ₽ </span>
                <Link to="/order">
                  <button className="btns">Заказать доставку</button>
                </Link>
              </div>
            </div>
            <div className="tarifs-content-cards-item">
              <div className="tarifs-content-cards-item-title">
                <h3>До 15 км от города</h3>
              </div>
              <div className="tarifs-content-cards-item-body">
                <ul>
                  <li>Ночная доставка + 90 ₽</li>
                  <li>Перевоз крупногабаритных объектов + 200 ₽</li>
                </ul>
                <span>от 950 ₽ </span>
                <Link to="/order">
                  <button className="btns">Заказать доставку</button>
                </Link>
              </div>
            </div>
            <div className="tarifs-content-cards-item">
              <div className="tarifs-content-cards-item-title">
                <h3>15 - 30 км от города</h3>
              </div>
              <div className="tarifs-content-cards-item-body">
                <ul>
                  <li>Ночная доставка + 90 ₽</li>
                  <li>Перевоз крупногабаритных объектов + 200 ₽</li>
                </ul>
                <span>от 1950 ₽ </span>
                <Link to="/order">
                  <button className="btns">Заказать доставку</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tarifs;
