import { Link } from "react-router-dom";
import logo from "../../img/logo.svg";
import pay from "../../img/pay.svg";

import "./footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-content">
          <div className="footer-content-logo mb-3">
            <img src={logo} alt="Логотип" />
          </div>
          <div className="footer-content-nav mb-3">
            <nav>
              <ul>
                <li>
                  <a href="#advantages">Преимущества</a>
                </li>
                <li>
                  <a href="#regions">Регионы присутствия</a>
                </li>
                <li>
                  <a href="#tarifs">Тарифы</a>
                </li>
                <li>
                  <a href="#delivery">Виды доставки</a>
                </li>
                <li>
                  <a href="#works">Как раотает?</a>
                </li>
                <li>
                  <Link to="contacts">Контакты</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="footer-content-docs mb-3">
            <ul>
              <li>
                <a href="/docs/oferta.pdf">Оферта</a>
              </li>
              <li>
                <a href="/docs/contract.pdf">Договор оказания услуг</a>
              </li>
              <li>
                <a href="/docs/secure.pdf">Беопасная сделка</a>
              </li>
              <li>
                <a href="/docs/rules.pdf">
                  Правила использования ПО «Courier Go»
                </a>
              </li>
              <li>
                <a href="/docs/privacy.pdf">
                  Политика обработки персональных данных
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-content-contacts mb-3">
            <ul>
              <li>ООО "Новые решения"</li>
              <li>ОГРН 1197847123143</li>
              <li>ИНН 7813635401</li>
              <li>КПП 781101001</li>
              <li className="mt-1">E-mail: info@courier-go.ru</li>
              <li>Телефон: +7 (495) 148-57-39</li>
            </ul>
          </div>
          <div className="footer-content-payments">
            <img src={pay} alt="Логотипы платежных систем" />
            <p>© 2022-2023 Courier Go</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
