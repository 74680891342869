import geo from "../../img/Geoposition.svg";
import "./region.scss";

function Region({ text }) {
  return (
    <div className="region">
      <img src={geo} alt="loacte" />
      <span>{text}</span>
    </div>
  );
}

export default Region;
