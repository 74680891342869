import { Link } from "react-router-dom";

import "./main.scss";

import man from "../../img/man.png";
import ellipse from "../../img/ellipse.svg";

function Main() {
  return (
    <main>
      <section className="main">
        <div className="container">
          <div className="row">
            <div className="main-content">
              <div className="main-info">
                <h1>Сервис быстрой доставки</h1>
                <p>
                  Предлагаем выгодные транспортные решения <br /> для каждого
                  клиента.
                </p>
                <Link to="/order">
                  <button className="btns">Заказать доставку</button>
                </Link>
              </div>

              <div className="main-img">
                <img className="man" src={man} alt="Man" />
                <img className="ellipse" src={ellipse} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

<svg
  width="1459"
  height="1415"
  viewBox="0 0 1459 1415"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M1459 707.5C1459 1098.24 1132.39 1415 729.5 1415C326.608 1415 0 1098.24 0 707.5C0 316.759 326.608 0 729.5 0C1132.39 0 1459 316.759 1459 707.5Z"
    fill="#999AD7"
    fill-opacity="0.6"
  />
</svg>;

export default Main;
