import { HashLink as Link } from "react-router-hash-link";
import { useContext } from "react";
import { MenuContext } from "../../context/navState";
import HamburgerButton from "../HamburgerButton.js";

import "./slide.scss";

const SlideMenu = () => {
  const { isMenuOpen, toggleMenuMode } = useContext(MenuContext);
  document.body.style = `overflow: ${isMenuOpen && "hidden"}`;
  return (
    <nav
      style={{
        transform: isMenuOpen ? "translateX(0)" : "translateX(100%)",
        width: "100%",
      }}
      className="mobile-menu"
    >
      <HamburgerButton />
      <div onClick={toggleMenuMode} className="mobile-menu-links">
        <Link className="nav-menu-link" to="/#advantages">
          Преимущества
        </Link>
        <Link className="nav-menu-link" to="/#regions">
          Регионы присутствия
        </Link>
        <Link className="nav-menu-link" to="/#tarifs">
          Тарифы
        </Link>
        <Link className="nav-menu-link" to="/#delivery">
          Виды доставки
        </Link>
        <Link className="nav-menu-link" to="/#works">
          Как работает?
        </Link>
        <div className="btn-block">
          <button className="btn-header">
            <Link to="/order">Заказать доставку</Link>
          </button>
          <button className="btn-header">
            <Link to="/candidate">Стать курьером</Link>
          </button>
          <a className="btn-lk" href="/">
            <svg
              className="person-link"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="person"
                d="M7.0001 0.599976C7.84879 0.599976 8.66272 0.937117 9.26284 1.53723C9.86296 2.13735 10.2001 2.95128 10.2001 3.79998C10.2001 4.64867 9.86296 5.4626 9.26284 6.06272C8.66272 6.66283 7.84879 6.99998 7.0001 6.99998C6.1514 6.99998 5.33747 6.66283 4.73736 6.06272C4.13724 5.4626 3.8001 4.64867 3.8001 3.79998C3.8001 2.95128 4.13724 2.13735 4.73736 1.53723C5.33747 0.937117 6.1514 0.599976 7.0001 0.599976M7.0001 8.59998C10.5361 8.59998 13.4001 10.032 13.4001 11.8V13.4H0.600098V11.8C0.600098 10.032 3.4641 8.59998 7.0001 8.59998Z"
                fill="#111A1B"
              />
            </svg>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default SlideMenu;
