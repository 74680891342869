import { ToTopButton } from "../../Components/ToTopButton";
import {
  Main,
  Advantages,
  Regions,
  Tarifs,
  Payments,
  Delivery,
  Works,
  Feedback,
} from "../../Layouts";

const Home = () => {
  return (
    <>
      <Main />
      <Advantages />
      <Regions />
      <Tarifs />
      <Payments />
      <Delivery />
      <Works />
      <Feedback />
    </>
  );
};

export default Home;
