import { Routes, Route } from "react-router";
import { Home, Contacts, Order, Candidate } from "./Pages";
import { ToTopButton } from "./Components/ToTopButton";
import { Header, Footer } from "./Layouts";
import { LocationState } from "./context";

import "./App.scss";

function App() {
  return (
    <LocationState>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/order" element={<Order />} />
          <Route path="/candidate" element={<Candidate />} />
        </Routes>
        <ToTopButton />
        <Footer />
      </div>
    </LocationState>
  );
}

export default App;
