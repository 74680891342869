import ellipse from "../../img/ellipse.svg";

import "./works.scss";

const Works = () => {
  return (
    <section id="works" className="works">
      <div className="container">
        <div className="works-content">
          <div className="works-content-title">
            <h2>Как это работает?</h2>
          </div>
          <div className="works-content-steps">
            <img className="ellipse" src={ellipse} alt="ellipse" />
            <div className="works-content-steps-item">
              <h3>01</h3>
              <span>Заявка</span>
              <p>Создайте заказ через сайт или по номеру телефона</p>
            </div>
            <div className="works-content-steps-item">
              <h3>02</h3>
              <span>Оформление</span>
              <p>Получите уведомление о назначении курьера</p>
            </div>
            <div className="works-content-steps-item">
              <h3>03</h3>
              <span>Доставка</span>
              <p>Курьер оперативно доставит получателю</p>
            </div>
            <div className="works-content-steps-item">
              <h3>04</h3>
              <span>Оплата</span>
              <p>Произведите оплату за доставку</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Works;
