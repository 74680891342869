import "./advantagex.scss";

import time from "../../img/time.svg";
import price from "../../img/price.svg";
import speed from "../../img/speed.svg";
import success from "../../img/success.svg";

const Advantages = () => {
  return (
    <section id="advantages" className="advantages">
      <div className="container">
        <div className="advantages-content">
          <div className="advantages-content-title">
            <h2>Преимущества</h2>
          </div>
          <div className="advantages-content-card">
            <div className="advantages-content-card-item">
              <img src={time} alt="Time" />
              <span>30 минут</span>
              <p>Среднее время доставки по городу</p>
            </div>
            <div className="advantages-content-card-item">
              <img src={price} alt="Time" />
              <span>от 350 ₽</span>
              <p>Стоимость курьерской доставки по городу</p>
            </div>
            <div className="advantages-content-card-item">
              <img src={speed} alt="Time" />
              <span>5 минут</span>
              <p>Скорость обработки заказа</p>
            </div>
            <div className="advantages-content-card-item">
              <img src={success} alt="Time" />
              <span>98%</span>
              <p>Успешность выполнения заявок</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
