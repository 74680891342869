import { createContext, useState } from "react";

export const LocationContext = createContext({
  region: "Москва",
  toggleRegion: () => {},
});

const LocationState = ({ children }) => {
  const [region, setRegion] = useState("Москва");

  function toggleRegion(reg) {
    setRegion(reg);
    localStorage.setItem("region", reg);
  }

  return (
    <LocationContext.Provider value={{ region, toggleRegion }}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationState;
