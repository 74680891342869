import { Link } from "react-router-dom";

import "./contacts.scss";
const Contacts = () => {
  return (
    <section className="contacts">
      <div className="container">
        <div className="contacts-content">
          <div className="contacts-content-title">
            <span>
              <Link to="/">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </Link>
            </span>
            <h2>Контакты</h2>
          </div>
          <div className="contacts-content-info">
            <div className="contacts-content-info-company">
              <h3>Юридическое лицо:</h3>
              <ul>
                <li>ООО «Новые решения»</li>
                <li>ОГРН 1197847123143</li>
                <li>ИНН 7813635401</li>
                <li>КПП 781101001</li>
              </ul>
            </div>

            <div className="contacts-content-info-adress">
              <h3>Адрес юридического лица:</h3>
              <p>
                192012, город Санкт-Петербург, проспект Обуховской обороны, дом
                116, корпус 1 литер.Е, пом./этаж 19Н №514/5 этаж
              </p>
            </div>
            <div className="contacts-contents-info-req">
              <h3>E-mail:</h3>
              <p>info@courier-go.ru</p>
              <h3>Телефон: </h3>
              <p>+7 (495) 148-63-74</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
