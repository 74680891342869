import { useContext } from "react";
import classnames from "classnames";
import { MenuContext } from "../../context/navState";

import "./burger.scss";

const HamburgerButton = () => {
  const { isMenuOpen, toggleMenuMode } = useContext(MenuContext);

  return (
    <button
      className={classnames("burger-btn", { active: isMenuOpen })}
      onClick={() => toggleMenuMode()}
    >
      <span className="burger-btn-line"></span>
      <span className="burger-btn-line"></span>
      <span className="burger-btn-line"></span>
    </button>
  );
};

export default HamburgerButton;
