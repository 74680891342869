import "./delivery.scss";

import clothes from "../../img/clothes.png";
import products from "../../img/products.png";
import gift from "../../img/gift.png";
import docs from "../../img/docs.png";
import medicines from "../../img/medicines.png";
import anything from "../../img/anything.png";

const Delivery = () => {
  return (
    <section id="delivery" className="delivery">
      <div className="container">
        <div className="delivery-content">
          <div className="delivery-content-title">
            <h2>Виды доставки</h2>
          </div>
          <div className="delivery-content-cards">
            <div className="delivery-content-cards-item">
              <h3>Одежда</h3>
              <p>Доставка одежды из интернет магазинов</p>
              <img src={clothes} alt="Одежда" />
            </div>
            <div className="delivery-content-cards-item">
              <h3>Продукты</h3>
              <p>Доставка продуктов до дверей в термосумках</p>
              <img src={products} alt="Продукты" />
            </div>
            <div className="delivery-content-cards-item">
              <h3>Подарки</h3>
              <p>Доставка подарков для близких и знакомых</p>
              <img src={gift} alt="Подарки" />
            </div>
            <div className="delivery-content-cards-item">
              <h3>Документы</h3>
              <p>Доставка документов в целости и сохранности</p>
              <img src={docs} alt="Документы" />
            </div>
            <div className="delivery-content-cards-item">
              <h3>Лекарства</h3>
              <p>Доставим медикаменты в самые короткие сроки</p>
              <img src={medicines} alt="Лекарства" />
            </div>
            <div className="delivery-content-cards-item">
              <h3>Все, что угодно</h3>
              <p>Доставка любых товаров на ваш вкус </p>
              <img src={anything} alt="Все, что угодно" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Delivery;
