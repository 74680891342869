import { useState } from "react";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";

import ellipse from "../../img/ellipse.svg";
import "./feedback.scss";

function Feedback() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    console.log(data);

    await fetch("../phpmailer/feedback.php", {
      body: JSON.stringify(data),
      method: "POST",
    })
      .then(() => {
        setIsLoading(false);
        setIsSuccess(true);
      })
      .then(() => {
        setTimeout(setIsSuccess, 5000, false);
      });
    reset();
  };

  return (
    <section className="feedback">
      <div className="container">
        <div className="feedback-content">
          <div className="feedback-content-title">
            <h2>Обратная связь</h2>
          </div>
          <div className="feedback-content-form">
            <img className="ellipse" src={ellipse} alt="ellipse" />
            <form className="fb-form" onSubmit={handleSubmit(onSubmit)}>
              {isSuccess && (
                <div className="alert">Сообщение успешно отправлено!</div>
              )}
              <label>Почта:</label>
              <input
                placeholder="example@yandex.com"
                type="email"
                {...register("email", {
                  required: true,
                  validate: (value) =>
                    /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i.test(value),
                })}
              />
              {errors.email && <p>Введите корректный почтовый адрес</p>}
              <label>Заголовок:</label>
              <input
                placeholder="Введите тему письма"
                type="text"
                {...register("title", {
                  required: true,
                  validate: (value) => value !== "",
                })}
              />
              {errors.title && <p>Введите тему письма</p>}
              <label>Сообщение:</label>
              <textarea
                rows="4"
                placeholder="Введите сообщение"
                type="text"
                {...register("text", {
                  required: true,
                  validate: (value) => value !== "",
                })}
              />
              {errors.text && <p>Пустое сообщение нельзя отправить</p>}

              <div className="form_btn">
                <button className="btns" type="submit">
                  {isLoading ? (
                    <Spinner animation="border" variant="warning" />
                  ) : (
                    "Отправить"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Feedback;
