import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { AddressSuggestions } from "react-dadata";
import InputMask from "react-input-mask";
import { Button, Spinner, Toast } from "react-bootstrap";
import ru from "date-fns/esm/locale/ru/index.js";
import DatePicker, { registerLocale } from "react-datepicker";

import "./candidate.scss";

const Candidate = () => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [birthday, setBirthday] = useState("");

  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (value) => {
    setIsLoading(true);
    fetch("./phpmailer/profile.php", {
      body: JSON.stringify(value),
      method: "POST",
    }).then(() => {
      setShow(true);
      setIsLoading(false);
      reset();
    });
  };
  return (
    <form className="profile_form" onSubmit={handleSubmit(onSubmit)}>
      <Toast
        style={{ position: "fixed", top: "20px", right: "20px", zIndex: 9999 }}
        show={show}
        onClick={() => setShow(false)}
      >
        <Toast.Header>
          <strong className="me-auto">Анкета успешно отправлена!</strong>
        </Toast.Header>
        <Toast.Body>Скоро мы с Вами свяжемся!</Toast.Body>
      </Toast>
      <h1 className="text-center mb-5">Форма анкеты</h1>

      <div className="group">
        <div className="group_item">
          {" "}
          <label>Фамилия:</label>
          <input
            type="text"
            {...register("lastname", {
              required: true,
              validate: (value) => value !== "",
            })}
            placeholder="Иванов"
          />
          {errors.lastname && <p>Введите Вашу фамилию</p>}
        </div>
        <div className="group_item">
          <label>Имя:</label>
          <input
            type="text"
            {...register("firstname", {
              required: true,
              validate: (value) => value !== "",
            })}
            placeholder="Петр"
          />
          {errors.firstname && <p>Введите Ваше имя</p>}
        </div>
      </div>

      <div className="group">
        <div className="group_item">
          <label>Отчество:</label>
          <input
            type="text"
            {...register("middlename", {
              required: true,
              validate: (value) => value !== "",
            })}
            placeholder="Алексеевич"
          />
          {errors.middlename && <p>Введите Ваше отчество</p>}
        </div>
        <div className="group_item">
          <label>Дата рождения:</label>
          <Controller
            control={control}
            name="birthday"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <>
                <DatePicker
                  {...register("birthday", {
                    required: true,
                    validate: (value) =>
                      value !== "" &&
                      value <
                        new Date() -
                          new Date(60 * 60 * 24 * 1000 * 365.25 * 18),
                    onChange: (e) => {
                      setBirthday(e.target.value);
                    },
                  })}
                  placeholderText="01.01.1999"
                  selected={value}
                  onChange={onChange}
                  locale={ru}
                  showMonthDropdown
                  autoComplete="off"
                  showYearDropdown
                  dropdownMode="select"
                  peekNextMonth
                  minDate={new Date(1962, 0, 1)}
                  maxDate={new Date()}
                  dateFormat="dd.MM.yyyy"
                />
              </>
            )}
          />

          {errors.birthday && <p>Возраст не соответствует требованиям</p>}
        </div>
      </div>

      <div className="group">
        <div className="group_item">
          <label>Серия и номер паспорта:</label>
          <Controller
            control={control}
            name="pasport_serial"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <>
                <InputMask
                  {...register("pasport_serial", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                  name="pasport_serial"
                  mask="99 99 999999"
                  maskChar={null}
                  placeholder="00 00 000000"
                >
                  {(inputProps) => <input {...inputProps} type="text" />}
                </InputMask>
              </>
            )}
          />
          {errors.pasport_serial && <p>Введите серию и номер паспорта</p>}
        </div>
        <div className="group_item">
          <label>Дата выдачи паспорта:</label>
          <Controller
            control={control}
            name="pass_date"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <>
                <DatePicker
                  {...register("pass_date", {
                    required: true,
                    validate: (value) =>
                      value !== "" &&
                      new Date(value).getFullYear() >
                        new Date(birthday).getFullYear() + 16,
                  })}
                  placeholderText="01.01.1999"
                  selected={value}
                  onChange={onChange}
                  locale={ru}
                  showMonthDropdown
                  autoComplete="off"
                  showYearDropdown
                  dropdownMode="select"
                  peekNextMonth
                  minDate={new Date(1962, 0, 1)}
                  maxDate={new Date()}
                  dateFormat="dd.MM.yyyy"
                />
              </>
            )}
          />

          {errors.pass_date && <p>Дата выдачи не соответствует возрасту</p>}
        </div>
      </div>

      <div className="group">
        <div className="group_item">
          <label>Адрес регистрации:</label>
          <Controller
            control={control}
            name="reg_adress"
            render={({ field }) => (
              <>
                <AddressSuggestions
                  {...register("reg_adress", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                  {...field}
                  count={5}
                  inputProps={{ placeholder: "г. Москва ул. Новый Арбат 12" }}
                  token="eb9dbc9d8b76b68399bd5aa3a3e5c7c31557e11d"
                ></AddressSuggestions>
              </>
            )}
          />

          {errors.reg_adress && <p>Укажите адрес вашей регистрации</p>}
        </div>
      </div>

      <div className="group">
        <div className="group_item">
          <label>Индекс:</label>
          <Controller
            control={control}
            name="index"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <>
                <InputMask
                  {...register("index", {
                    required: true,
                    validate: (value) => value !== "",
                  })}
                  name="index"
                  mask="999999"
                  maskChar={null}
                  placeholder="000000"
                >
                  {(inputProps) => <input {...inputProps} type="text" />}
                </InputMask>
              </>
            )}
          />
          {errors.index && <p>Введите индекс</p>}
        </div>
        <div className="group_item">
          <label>Номер телефона:</label>
          <Controller
            control={control}
            name="phone"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <>
                <PhoneInput
                  inputClass="shadow-none"
                  placeholder="+7 (999) 999-99-99"
                  onChange={onChange}
                  value={value}
                  country={"ru"}
                  disableDropdown={true}
                />
              </>
            )}
          />
          {errors["phone"] && <p>Введите номер телефона</p>}
        </div>
        <div className="group_item">
          <label>Почта:</label>
          <input
            {...register("email", {
              required: true,
              validate: (value) => value !== "",
            })}
            type="email"
            placeholder="ivaninf@yandex.com"
          />

          {errors.email && <p>Введите Вашу почту</p>}
        </div>
      </div>

      <div className="btn-block">
        <button className="btns" type="submit" variant="warning">
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Отправить анкету"
          )}
        </button>
      </div>
      <div
        style={{ opacity: "0.6", fontSize: "12px", textAlign: "center" }}
        className="mt-2"
      >
        Нажимая кнопку "Отправить анкету", вы соглашаетесь с{" "}
        <a href="/">политикой обработки персональных данных</a>{" "}
      </div>
    </form>
  );
};

export default Candidate;
