import { useContext, useRef, useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Modal } from "react-bootstrap";
import { MenuContext } from "../../context/navState.js";
import { LocationContext } from "../../context/LocationState.js";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { NavState, LocationState } from "../../context";

import HamburgerButton from "../../Components/HamburgerButton.js";
import SlideMenu from "../../Components/SlideMenu";

import logo from "../../img/logo.svg";
import location from "../../img/location.svg";

import "./Header.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const LocationModal = (props) => {
  const { toggleRegion } = useContext(LocationContext);

  return (
    <Modal
      size="md"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ padding: "10px 32px" }} closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Выберите регион
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "10px 32px" }} className="d-flex">
        <ul onClick={() => props.onHide()} className="location_list">
          <li onClick={() => toggleRegion("Москва")}>Москва</li>
          <li onClick={() => toggleRegion("Краснодар")}>Краснодар</li>
          <li onClick={() => toggleRegion("Санкт-Петербург")}>
            Санкт-Петербург
          </li>
        </ul>
        <ul onClick={() => props.onHide()} className="location_list">
          <li onClick={() => toggleRegion("Екатеринбург")}>Екатеринбург</li>
          <li onClick={() => toggleRegion("Новосибирск")}>Новосибирск</li>
          <li onClick={() => toggleRegion("Казань")}>Казань</li>
        </ul>
        <ul onClick={() => props.onHide()} className="location_list">
          <li onClick={() => toggleRegion("Нижний Новгород")}>
            Нижний Новгород
          </li>
          <li onClick={() => toggleRegion("Челябинск")}>Челябинск</li>
          <li onClick={() => toggleRegion("Омск")}>Омск</li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

const Location = () => {
  const [modalShow, setModalShow] = useState(false);
  const { region, toggleRegion } = useContext(LocationContext);

  useEffect(() => {
    if (!localStorage.getItem("region")) {
      setModalShow(true);
    }

    if (localStorage.getItem("region")) {
      toggleRegion(localStorage.getItem("region"));
    }
  }, []);

  return (
    <>
      <button onClick={() => setModalShow(true)} className="location">
        <img src={location} alt="location" />
        {region}
      </button>

      <LocationModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

function Header() {
  const node = useRef();
  const { isMenuOpen, toggleMenuMode } = useContext(MenuContext);

  useOnClickOutside(node, () => {
    if (isMenuOpen) {
      toggleMenuMode();
    }
  });

  return (
    <NavState>
      <header ref={node}>
        <div className="container">
          <div className="header-content-mobile">
            <Link to="/">
              <img src={logo} alt="Логотип" />
            </Link>
            <HamburgerButton />
            <SlideMenu />
          </div>
          <div className="header-content">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Логотип" />
              </Link>

              <Location />
            </div>
            <nav>
              <Link className="nav-menu-link" to="/#advantages">
                Преимущества
              </Link>
              {/* <a className="nav-menu-link" href="#advantages">
                Преимущества
              </a> */}
              <Link className="nav-menu-link" to="/#regions">
                Регионы присутствия
              </Link>
              <Link className="nav-menu-link" to="/#tarifs">
                Тарифы
              </Link>
              <Link className="nav-menu-link" to="/#delivery">
                Виды доставки
              </Link>
              <Link className="nav-menu-link" to="/#works">
                Как работает?
              </Link>
            </nav>
            <div className="btn-block">
              <button className="btn-header">
                <Link to="/order">Заказать доставку</Link>
              </button>
              <button className="btn-header">
                <Link to="/candidate">Стать курьером</Link>
              </button>
              <a className="btn-lk" href="https://lk.courier-go.su">
                <svg
                  className="person-link"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="person"
                    d="M7.0001 0.599976C7.84879 0.599976 8.66272 0.937117 9.26284 1.53723C9.86296 2.13735 10.2001 2.95128 10.2001 3.79998C10.2001 4.64867 9.86296 5.4626 9.26284 6.06272C8.66272 6.66283 7.84879 6.99998 7.0001 6.99998C6.1514 6.99998 5.33747 6.66283 4.73736 6.06272C4.13724 5.4626 3.8001 4.64867 3.8001 3.79998C3.8001 2.95128 4.13724 2.13735 4.73736 1.53723C5.33747 0.937117 6.1514 0.599976 7.0001 0.599976M7.0001 8.59998C10.5361 8.59998 13.4001 10.032 13.4001 11.8V13.4H0.600098V11.8C0.600098 10.032 3.4641 8.59998 7.0001 8.59998Z"
                    fill="#111A1B"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </header>
    </NavState>
  );
}

export default Header;
