import "./payments.scss";

import cashs from "../../img/cashs.svg";
import cards from "../../img/cards.svg";

const Payments = () => {
  return (
    <section className="payments">
      <div className="container">
        <div className="payments-content">
          <div className="payments-content-title">
            <h2>Способы оплаты</h2>
          </div>
          <div className="payments-content-body">
            <p>
              Выберите удобный для себя способ оплаты и оплати доставку при
              передачи груза курьеру.
            </p>
            <div className="payments-content-body-icon">
              <div className="payments-content-body-icon-item">
                <img src={cashs} alt="Наличными" />
                <span>Наличными</span>
              </div>
              <div className="payments-content-body-icon-item">
                <img src={cards} alt="Картой" />
                <span>Картой</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Payments;
